<template>   
  <b-card-text>
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col md="10" v-if="crud.form.ID1">
            <b-form-group label="Cantidad por defecto al agregar producto">
              <b-form-input type="number"
                            step="1"
                            size="sm"
                            v-model="crud.form.ID1.value_number"
                            placeholder="Cantidad por defecto">
              </b-form-input>
            </b-form-group>
          </b-col>     
        </b-row>
        <b-row class="mt-3">
          <b-col md="10" v-if="crud.form.ID2">        
            <FindObject render="search"
                        type="customers" 
                        tag="Cliente por defecto en nuevo comprobante"
                        @select-object="loadCustomers($event)" 
                        :valueID="crud.form.ID2.value_number"
                        :key="crud.form.ID2.value_number"
                        :where="conditionCustomersActive"/>                                   
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="showRemits">
          <b-col md="10" sm="12" v-if="crud.form.ID3">   
            <b-form-group label="Remito por defecto al realizar un comprobante de venta" 
                          description="Esta opción predetermina el checkbox para la generación del remito, puede cambiar la configuración en el mismo proceso de venta">       
              <b-form-select v-model="crud.form.ID3.value_string" :options="crud.form.ID3.value_string_opt"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="10" v-if="crud.form.ID11">
            <b-form-group label="Imprimir comprobante desde Impresora Térmica (ESC/POS)" 
                          description="Debe instalar el sistema intermedio 'escpos_print_serve'">
              <b-form-input type="text"                            
                            size="sm"
                            v-model="crud.form.ID11.value_string"
                            placeholder="URL (endpoint para enviar los datos del comprobante)">
              </b-form-input>
            </b-form-group>
          </b-col>     
        </b-row>        
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12" sm="12">
            <b-form-group label="Límite de Crédito">
                <b-form-checkbox                  
                  v-model="crud.form.ID4.value_boolean"
                  switch
                >
                  Válidar el límite de crédito de clientes en cada venta
                </b-form-checkbox>
                
            </b-form-group>
          </b-col>  
        </b-row>
        <b-row>
          <b-col md="12" sm="12">
            <b-form-group label="Recibo en Venta">
                <b-form-checkbox                  
                  v-model="crud.form.ID5.value_boolean"
                  switch
                >
                  Activar el recibo en la generación de facturas simples
                </b-form-checkbox>
                
            </b-form-group>
          </b-col>  
        </b-row>
        <b-row>
          <b-col md="12" sm="12">
            <b-form-group label="Edición de precios">
                <b-form-checkbox                  
                  v-model="crud.form.ID6.value_boolean"
                  switch
                >
                  Bloquear edición de precio en venta
                </b-form-checkbox>
                
            </b-form-group>
          </b-col>  
        </b-row>        
        <b-row>
          <b-col md="12" sm="12">
            <b-form-group label="Transporte">
                <b-form-checkbox                  
                  v-model="crud.form.ID7.value_boolean"
                  switch
                >
                  Habilitar el transporte en la carga de la venta
                </b-form-checkbox>
                
            </b-form-group>
          </b-col>  
        </b-row>
        <b-row>
          <b-col md="12" sm="12">
            <b-form-group label="Fechas">
                <b-form-checkbox                  
                  v-model="crud.form.ID8.value_boolean"
                  switch
                >
                  Bloquear la edición de las fechas en la generación de comprobantes
                </b-form-checkbox>
                
            </b-form-group>
          </b-col>  
        </b-row>
        <b-row>
          <b-col md="12" sm="12">
            <b-form-group label="Métricas">
                <b-form-checkbox                  
                  v-model="crud.form.ID9.value_boolean"
                  switch
                >
                Permite filtrar por rango horario desde/hasta en las metricas de ventas
                </b-form-checkbox>
                
            </b-form-group>
          </b-col>  
        </b-row>
        <b-row>
          <b-col md="12" sm="12">
            <b-form-group label="Productos">
                <b-form-checkbox                  
                  v-model="crud.form.ID10.value_boolean"
                  switch
                >
                Mantiene la última búsqueda de productos
                </b-form-checkbox>
                
            </b-form-group>
          </b-col>  
        </b-row>        
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">                          
        <b-button variant="dark" size="sm" @click="save()">Guardar</b-button>                        
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col>
        <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
          <i class="fa fa-angle-double-left"></i>
          Volver
        </b-button>                        
      </b-col>      
    </b-row>                       
  </b-card-text>
</template>
<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Session from '@/handler/session'  
  import TabBusiness from '@/components/inc/myBusiness/business'
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    components: {        
      TabBusiness,
      FindObject,
    },    
    data: () => {
      return {              
        crud: { 
          form: {
            ID1: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
            ID2: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },  
            ID3: {
              id: 0,
              value_string: 'default',
              value_string_opt: [
                {value: 'default', text: 'Emite remito segun el origen de la venta'},
                {value: 'always-remit', text: 'Siempre emitir remito'},
                {value: 'never-remit', text: 'Nunca emitir remito'},
              ],
              value_number: 0,
              value_boolean: false,
            },  
            ID4: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            }, 
            ID5: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },  
            ID6: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
            ID7: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            }, 
            ID8: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
            ID9: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            }, 
            ID10: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },  
            ID11: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },                                                                                                                                                                                               
          },             
        },
        tabIndex: 0,       
        modulesActive: [],        
      }
    },
    mounted () {      
      this.show()      
    },
    computed: {
      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },          

      showRemits() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.REMITOS) {                             
            status = true                       
          }
        })        
        return status
      },      

    },
    methods: {
      // SELECT
      loadCustomers (object) {        
        if(object){          
          this.crud.form.ID2.value_number = object.id             
        } else {          
          this.crud.form.ID2.value_number = 0          
        }
      }, 
              
      show() {   
        var result = serviceAPI.obtenerConfiguracion()

        result.then((response) => {
          var data = response.data
          
          data.forEach(element => {            
            if(element.id == 1)  {
              this.crud.form.ID1.id = element.id
              this.crud.form.ID1.value_number = parseInt(element.value_number)
            }            
            
            if(element.id == 2)  {
              this.crud.form.ID2.id = element.id
              this.crud.form.ID2.value_number = parseInt(element.value_number)
            }
            
            if(element.id == 3)  {
              this.crud.form.ID3.id = element.id
              this.crud.form.ID3.value_string = element.value_string
            }            

            if(element.id == 4)  {
              this.crud.form.ID4.id = element.id
              this.crud.form.ID4.value_boolean = element.value_boolean
            }                        

            if(element.id == 5)  {
              this.crud.form.ID5.id = element.id
              this.crud.form.ID5.value_boolean = element.value_boolean
            }    

            if(element.id == 6)  {
              this.crud.form.ID6.id = element.id
              this.crud.form.ID6.value_boolean = element.value_boolean
            }  
            
            if(element.id == 7)  {
              this.crud.form.ID7.id = element.id
              this.crud.form.ID7.value_boolean = element.value_boolean
            }    
            
            if(element.id == 8)  {
              this.crud.form.ID8.id = element.id
              this.crud.form.ID8.value_boolean = element.value_boolean
            }                

            if(element.id == 9)  {
              this.crud.form.ID9.id = element.id
              this.crud.form.ID9.value_boolean = element.value_boolean
            }
            
            if(element.id == 10)  {
              this.crud.form.ID10.id = element.id
              this.crud.form.ID10.value_boolean = element.value_boolean
            }          
            
            if(element.id == 11)  {
              this.crud.form.ID11.id = element.id
              this.crud.form.ID11.value_string = element.value_string
            }               
          });          
        })
        .catch(error => {              
          this.$awn.alert(Error.showError(error));
        })        
      },              
      save() {           
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            let loader = this.$loading.show();
            
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID1);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID2);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID3);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID4);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID5);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID6);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID7);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID8);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID9);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID10);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID11);  
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },    
    } 
  }
</script>